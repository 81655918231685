import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { media } from "../styles/media";

const Footer = () => {
  return (
    <Container>
      <div className="width-box">
        <img src="/images/logoB.png" width={137} height={16} />
            <Link to="/service">
              <h5>이용약관</h5>
            </Link>
            <Link to="/privacy">
              <h5>개인정보 처리방침</h5>
            </Link>
      </div>
    </Container>
  );
};
const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 0;
  background: #f2f4f6;

  ${media.tablet`
      padding: 40px 0px 40px 10px;
    `}

  a {
    text-decoration: none;
    margin-left: 10px;
  }

  img {

  }
  .width-box {
    display: flex;
    grid-gap: 24px;
    align-items: center;
    flex-direction: row;
    
    ${media.tablet`
      grid-gap: 6px;
    `}
  }
`;
export default Footer;
