import { Outlet, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import GlobalStyle from "./styles/global";
import Header from "./component/header";
import Footer from "./component/footer";
import { useEffect, useState } from "react";
import { useWindowSize } from "./static/menu";

const App = () => {
  const height = useWindowSize();
  const { pathname } = useLocation();
  const left = pathname === "/privacy";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container left={left}>
      <GlobalStyle />
      <Header height={height} />
      <Outlet />
      <Footer />
    </Container>
  );
};

interface style {
  left?: boolean;
}

const Container = styled.div<style>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.left ? "flex-start" : "center")};
  width: 100%;
`;

export default App;
