import { useEffect, useState } from "react";

export const Api = process.env.REACT_APP_API;
export const Dev = process.env.REACT_APP_DEV;

export const DevelopImg = [
  { label: "프로젝트 백서 제작", img: "/images/img1.jpg" },
  { label: "토큰발행 등 블록체인 개발", img: "/images/img2.jpg" },
  { label: "웹사이트 제작", img: "/images/img3.jpg" },
  { label: "마케팅 및 이벤트 진행", img: "/images/img4.jpg" },
];
interface Comment {
  comment: string;
}
interface UseType {
  subject?: string | undefined;
  title?: string | undefined;
  sub?: string | undefined;
  sub2?: {
    comment: Comment;
  };
  sub3?: {
    comment: Comment;
  };
}

export const UseService = [
  {
    subject: "제 1장 총칙",
    class: "margin",
    title: "제 1 조 (목적)",
    sub: `본 약관은 ㈜블록테크(이하 "회사"라 함)이 제공하는 인터넷 서비스(이하 "서비스"라 합니다)의 이용과 관련하여 회사와 회원의 권리, 의무 및 기타 필요한 사항을 규정함을 목적으로 합니다.`,
  },
  {
    title: "제 2 조 (용어의 정의)",
    sub: `본 약관에서 사용하는 주요한 정의는 다음과 같습니다.`,
    sub2: [
      {
        comment: `1) '사이트'란 블록테크( https://www.blocktechcorp.co.kr )를 말합니다.`,
      },
      {
        comment:
          "2) '이용자'란 사이트에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을 말합니다",
      },
      {
        comment: `3) '제휴회원'이란 마케팅 또는 콘텐츠 제휴 등을 목적으로 회사와 계약 관계에 있는 개인 또는 단체를 말합니다.`,
      },
      {
        comment: `4) '포스트 미디어'란 제휴이용자가 블로그 또는 소셜 미디어 등 온라인 전반에 작성 및 등록한 미디어를 말합니다`,
      },
    ],
  },
  {
    title: "제 3 조 (약관의 효력 및 변경)",
    sub2: [
      {
        comment: `1) 본 약관의 내용은 이용자 및 제휴회원이 쉽게 알 수 있도록 서비스 화면에 게시합니다.`,
      },
      {
        comment: `2) 회사는 필요하다고 인정되는 경우 본 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일 및 변경 내용을 명시하여 제1항의 방법으로 그 적용일의 최소 7일 전부터 공지하고 다     만, 이용자 및 제휴 회원에게 불리한 약관의 변경인 경우에는 최소 30일 전부터 공지합니다. 회사는 이용자 및 제휴 회원에게 불리한 약관 변경의 경우 이용자 및 제휴 회원 정보에 기재된 이메일로 개별 통지합니다.`,
      },
      {
        comment: `3) 회사가 제2항에 따라 약관의 변경을 공지 또는 통지하면서 변경 약관의 적용일까지 거부 의사를 표시 하지 않으면 약관 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 불구하고 이용자가 명시적으로 거부 의사를 표시하지 않은 경우 변경 약관에 동의한 것으로 봅니다. 이용자는 변경된 약관에 동의하지 않는 경우 이용 계약을 해지할 수 있습니다.`,
      },
    ],
  },
  {
    title: "제 4 조 (약관 외 준칙)",
    sub: "본 약관에 명시되지 아니한 사항에 대해서는 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 기타 관련 법령의 규정에 따릅니다.",
  },
  {
    subject: "제 2장 서비스 이용 계약",
    class: "margin",
    title: "제 5 조 (이용 계약의 성립)",
    sub: "이용 계약은 회사의 서비스를 이용하고자하는 자 (이하 “이용자”라 합니다.)가 본 이용약관 및 개인정보 처리 방침에 동의한 다음 개인정보를 입력하고, 전송한 후 회사가 해당 신청을 승낙함으로써 체결됩니다.",
  },
  {
    title: "제 6 조 (이용 신청의 승낙 • 유보)",
    sub: " 1) 서비스 이용자는 회사가 정한 소정의 양식에 필요한 정보를 입력하고 서비스 이용을 신청합니다.",
  },
  {
    sub: " 2) 회사는 다음 각 호의 어느 하나에 해당하는 경우 그 사유가 해소될 때까지 승낙 및 답변을 유보할 수 있습니다.",
    sub3: [
      { comment: "가. 서비스 관련 설비에 여유가 없는 경우" },
      { comment: "나. 기술상 지장이 있는 경우" },
      { comment: "다. 기타 위 각 호에 준하는 사유가 존재하는 경우" },
      { comment: "라. 우선적으로 신청된 서비스 이용 문의를 처리 중인 경우" },
    ],
  },
  {
    sub: "3) 회사는 다음 각 호의 어느 하나에 해당하는 경우 이용 신청에 대한 승낙 및 답변을 거부할 수 있으며, 승낙 이후 그 사유가 발견된 경우에는 이용제한 조치를 취할 수 있습니다.",
    sub3: [
      {
        comment:
          "가. 타인의 명의 및 이용자와 관계없는 법인명의를 사용하여 이용을 신청한 경우",
      },
      { comment: "나. 필요한 정보를 허위로 기재하여 이용을 신청한 경우" },
      {
        comment:
          "다. 만 14세 미만의 아동이 부모 등 법정 대리인의 동의를 얻지 않고 이용을 신청한 경우",
      },
      {
        comment:
          "라. 기타 관련 법령을 위반하거나 회사가 정한 요건에 맞지 않게 이용을 신청한 경우",
      },
    ],
  },
  {
    sub: "4) 스스로 서비스 이용을 해지한 종전 이용자가 해지일로부터 3개월이 지나지 않은 상태에서 이용을 신청하는 경우 회사는 이를 승낙 및 답변하지 않을 수 있습니다.",
  },
  {
    sub: "5) 약관 제10조 위반으로 회사에 의해 이용계약이 해지된 종전 이용자가 계약 해지일로부터 1년 내에 다시 이용을 신청하는 경우 회사는 이를 승낙하지 않을 수 있습니다.",
  },
  {
    title: "제 7 조 (회원정보의 변경)",
    sub: "이용자는 이용 신청 시 기재한 사항이 변경되었을 경우 전자우편 기타 방법으로 회사에 변경 사항을 알려야 합니다. 회사는 이용자가 변경 사항을 알리지 않아 발생한 불이익에 대하여 책임을 지지 않습니다.",
  },
  {
    title: "제 8 조 (개인정보의 보호 및 보관 기간)",
    sub2: [
      {
        comment:
          "1) 회사는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률”, “개인정보보호법” 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.",
      },
      {
        comment:
          "2) 개인정보처리방침의 범위 내에서 회사는 업무와 관련하여 이용자 전체 또는 일부의 개인정보에 관한 집합적인 통계 자료를 작성하여 이를 사용할 수 있고 서비스를 통하여 이용자의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 이용자는 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있습니다.",
      },
      {
        comment: `3) 이용자가 서비스 이용이 종료되었을 경우, 이용자의 개인정보는 분리하여 보관합니다.
      회사는 이용자가 서비스를 재개하거나 법령에 특별한 규정이 있는 경우를 제외하고는 분리된 개인 정보를 이용하거나 제공하지 않습니다.`,
      },
      {
        comment:
          "4) 이용자의 이용 계약이 종료된 경우 회사는 권리남용을 방지하고 권리침해와 관련한 분쟁에 대비하기 위하여 종료일로부터 12개월 동안 회원의 개인정보를 보유할 수 있습니다. 다만, 제6조  제5항에 따라 회사가 계약을 종료한 경우에는 부정 가입 및 이용 방지를 위하여 2년 동안 회원의 연계 정보(CI)를 보관할 수 있습니다.",
      },
    ],
  },
  {
    subject: "제 3장 계약 당사자의 의무 및 보안사항",
    class: "margin",
    title: "제 9 조 (회사의 의무)",
    sub2: [
      {
        comment:
          "1) 회사는 계속적이고 안정적인 서비스의 제공을 위하여 지속적으로 노력하며, 설비에 장애가 생기거나 멸실된 때에는 지체 없이 이를 수리, 복구하여야 합니다. 다만, 천재지변, 비상사태 또는 그 밖에 부득이한 경우에는 그 서비스를 일시 중단하거나 정지할 수 있습니다.",
      },
      {
        comment:
          "2) 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위한 보안시스템을 갖추어야 하며, 유지, 점검 및 복구 등의 조치를 성실히 이행합니다.",
      },
      {
        comment:
          "3) 제3자가 회사와 계약을 체결하고, 이에 근거하여 회사의 이용자들에게 서비스를 제공하고자 하는 경우, 회사는 이용자에게 개별적 동의를 받은 뒤, 동의의 범위 내에서 제3자에게 이용자의 개인정보를 제공할 수 있고, 이 경우 회사는 이용자의 개인정보를 보호하기 위한 관련법령의 의무를 준수합니다.",
      },
      {
        comment:
          "4) 회사는 소정의 절차에 의해 제기되는 이용자의 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리 하여야 합니다. 즉시 처리가 불가능한 경우에는 이용자에게 그 사유와 처리 일정을 통지해야 합니다. 다만, 사실관계의 확인이 필요한 경우 등 처리에 소요되는 시간을 정확히 예측하기 어려운 경우에는 이용자에게 그 사유를 통보하고 상당한 기간 내에 이를 처리할 수 있습니다.",
      },
    ],
  },
  {
    title: "제 10 조 (이용자의 의무)",
    sub: "1) 이용자는 관계법령, 본 약관의 규정, 이용수칙 등 회사가 공지 또는 통지하는 사항을 준수하여야 하며, 회사의 업무를 방해하는 행위를 할 수 없습니다.",
  },
  {
    sub: "2) 이용자는 회사의 사전승낙 없이 회사가 저작권을 보유한 게시물을 복제, 전송, 수정, 번역, 출판, 배포, 방송하거나 기타 방법으로 사용하거나 제3자에게 제공하거나, 영리를 목적으로 이용할 수 없습니다.",
  },
  {
    sub: "3) 이용자는 회사의 사전승낙 없이 서비스를 이용하여 광고 등 영리행위를 할 수 없습니다. 회사는 사전 승낙을 받지 않은 회원의 영리행위로 인해 발생한 결과에 대하여 책임을 부담하지 않습니다. 이용자는 사전승낙을 받지 않은 영리행위로 인해 회사에 손해가 발생한 경우 회사에 대하여 손해배상 책임을 부담합니다.",
  },
  {
    sub: "4) 이용자가 게시물 작성 등 서비스 이용과 관련하여 제3자의 초상권, 상표권, 저작권 및 기타 권리를 사용하고자 하는 경우에는 사전에 정당한 권리자로부터 필요한 권리를 확보하여야 하며, 권한 없는 사용으로 권리자와 분쟁이 발생한 경우에는 이용자가 모든 책임을 부담합니다.",
  },
  {
    sub: "5) 이용자는 회사의 서비스 안정성에 영향을 미칠 수 있거나 회사가 용인하지 않은 방법으로 서비스를 이용하여서는 안되며, 서비스 이용에 있어서 부적절한 방법을 유포해서는 안됩니다.",
  },
  {
    sub: "6) 이용자는 서비스 이용과 관련하여 다음 각 호에 해당되는 행위를 하여서는 안됩니다.",
    sub3: [
      { comment: "가. 타인의 명의를 이용하여 부당하게 서비스를 이용하는 행위" },
      {
        comment:
          "나. 회사 또는 제3자의 저작권, 초상권 및 기타 권리를 침해하는 행위",
      },
      { comment: "다. 공공질서 및 미풍양속에 위반되는 내용을 유포하는 행위" },
      {
        comment: "라. 선정적이거나 사회적 물의를 일으키는 내용을 유포하는 행위",
      },
      { comment: "마. 범죄와 결부된다고 객관적으로 판단되는 행위" },
      {
        comment:
          "바. 상습적으로 회원간 분쟁을 유도하는 등 커뮤니티의 발전을 저해하는 행위",
      },
      {
        comment:
          "사. 바이러스, 악성 코드 등을 유포하거나 해킹 등을 시도하는 행위",
      },
      {
        comment:
          "아. 다량의 정보를 전송하거나 광고성 정보를 전송하여 서비스의 안정적 운영을 방해하는 행위",
      },
      { comment: "자. 회사의 운영진, 관리자, 관계자를 사칭하는 행위" },
      { comment: "차. 위 각 호의 내용이 포함되었다고 판단되는 행위" },
      {
        comment:
          "카. 회사의 서비스를 방해하거나, 서비스에 고의적으로 피해를 끼치는 행위",
      },
      { comment: "타. 기타 관계 법령에 위배되는 행위" },
    ],
  },
  {
    title: "제 11 조 (제공 서비스의 보안)",
    sub: "당사가 제공하는 서비스(개발, 마케팅, 솔루션, 및 회사와 이용자간에 합의된 기타 서비스 내용 등)와 관련된 내용은 외부 공유 및 유출할 수 없습니다. 또한, 회사는 이용자의 외부 공유, 유출로 인한 피해의 대한 책임을지지 않으며, 그로 인해 회사에게도 피해가 있을 경우 회사는 이용자에게 손해배상 청구를 할 수 있습니다.",
  },
  {
    subject: "제 4장 서비스 이용 및 제공",
    class: "margin",
    title: "제 12 조 (서비스 이용 범위)",
    sub: "회사는 필요한 경우 이용자의 연령, 본인 인증 여부 또는 부여한 등급에 따라 일부 서비스에 대한 접근을 제한할 수 있습니다.",
  },
  {
    title: "제 13 조 (서비스 제공)",
    sub: "회사는 사이트에 명시한 서비스를 이용자에게 제공하며 그 내용은 아래와 같습니다.",
    sub3: [
      { comment: "가. 디파이 / 홈페이지(사이트) 개발 의뢰" },
      { comment: "나. 마케팅 / 솔루션 의뢰" },
      { comment: "다. 가상자산 사업 자문 의뢰" },
      { comment: "라. 그 외 회사와 이용자가 상호 합의한 서비스" },
    ],
  },
  {
    title: "제 14 조 (정보의 제공 및 수집)",
    sub2: [
      {
        comment:
          "1) 회사는 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편, 서신우편, SMS(MMS)등의 방법으로 이용자에게 제공할 수 있습니다.",
      },
      {
        comment:
          "2) 회사는 이용자가 전항의 정보를 원치 않는다는 의사를 밝히는 경우 정보 제공 대상에서 해당 이용자를 제외하여야 합니다. 다만, 회사는 이로 인해 발생한 불이익에 대하여 책임을 부담하지 않습니다.",
      },
      {
        comment:
          "3) 회사는 이용자의 서비스 이용 및 논의 간에 이루어지는 모든 통신 내용을 저장·보관할 수 있습니다. 이 정보는 회사만이 보유합니다. 회사는 이용자와의 분쟁 조정, 민원 처리를 위한 경우에 한하여, 제3자는 법령에 따라 권한이 부여된 경우에 한하여 이 정보를 열람할 수 있습니다.",
      },
    ],
  },
  {
    title: "제 15 조 (광고게재 및 광고주와의 거래)",
    sub2: [
      {
        comment:
          "1) 회사가 제공하는 서비스에는 회사 이외의 광고주의 판촉활동을 위한 서비스가 포함될 수 있습니다. 서비스를 이용하는 자는 서비스 이용 시 노출되는 광고게재에 대해 동의하는 것으로 간주됩니다.",
      },
      {
        comment:
          "2) 회사는 본 사이트에 게재된 광고주의 광고나 판촉활동의 진실성을 담보하지 않으며, 이용자가 본 사이트에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 참여하여 거래한 결과로서 발생하는 손실과 손해에 대해서는 책임을 지지 않습니다.",
      },
    ],
  },
  {
    title: "제 16 조 (제휴회원의 게시물)",
    sub: "1) 회사는 이용자가 요청한 서비스를 통해 등록된 제휴회원의 게시물에 대한 어떠한 민형사상 책임도 부담하지 않으며, 다음 각 호 어느 하나에 해당하는 경우에는 사전통지 없이 해당 게시물을 삭제할 수 있습니다.",
    sub3: [
      {
        comment:
          "가. 타인을 모욕하거나, 명예를 훼손하거나, 프라이버시를 침해하는 경우",
      },
      { comment: "나. 공공질서 및 미풍양속에 위반되는 내용인 경우" },
      { comment: "다. 범죄행위와 결부되었다고 인정되는 내용일 경우" },
      { comment: "라. 제3자의 저작권 및 기타 권리를 침해하는 경우" },
      {
        comment: "마. 회사에서 규정한 게시 목적이나 기간, 용량을 초과한 경우",
      },
      { comment: "바. 선정적인 음란물을 게재하거나 관련 정보가 포함된 경우" },
      { comment: "사. 반사회적이거나 사회적 물의를 일으키는 내용인 경우" },
      { comment: "아. 회사가 공지 또는 통지한 이용수칙을 위반한 경우" },
      { comment: " 자. 본 약관 10조를 위반하는 게시물인 경우" },
      { comment: "차. 기타 관계법령에 위반된다고 판단되는 경우" },
    ],
  },
  {
    sub: `2) 서비스를 통해 등록한 게시물로 인해 사생활이 침해되거나 명예가 훼손되는 등 권리를 침해 받은 자는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률"에 따라 침해 사실을 소명하여 해당 게시물의 삭제 요청을 할 수 있습니다. 이 경우 삭제 요청을 하는 자는 본인이 권리를 침해 받은 당사자(혹은 대리인) 임을 증명할 수 있는 자료와 함께 해당 게시물의 위치 정보(URL)를 기재하여 요청하여야 합니다.`,
  },
  {
    sub: "3) 회사는 위 2항에 따라 삭제를 요청 받은 게시물이 사생활 침해 또는 명예훼손 등 권리를 침해한다고 인정하는 경우 지체없이 삭제 등의 조치를 취하여야 합니다. 다만, 회사가 해당 게시물 등의 권리 침해 여부를 판단할 수 없거나 당사자 간의 다툼이 예상되는 경우 해당 게시물에 대해 10일간의 임시 조치를 진행합니다.",
  },
  {
    title: "제 17 조 (게시물에 대한 권리 및 책임)",
    sub2: [
      {
        comment:
          "1) 이용자가 요청한 서비스를 통하여 게시된 게시물의 저작권은 해당 게시물의 저작자(제휴회원)에게 귀속됩니다.",
      },
      {
        comment:
          "2) 이용자는 회사 및 회사의 제휴회원에게 해당 게시물을 일부 수정, 번역, 편집하여 국내외에서 사용할 수 있는 권리를 부여한 것으로 간주합니다. 이 경우, 회사는 저작권법을 준수하며 이용자는 언제든지 고객센터를 통해 해당 게시물의 사용 중단을 요청하거나 삭제할 수 있습니다.",
      },
      {
        comment:
          "3) 회사는 사전의 발간, 언어 연구, 언어 데이터베이스 구축 등을 위하여 이용자 및 제휴회원의 게시물을 수집, 보존, 수정, 배포, 복제, 제공하는 등 자유롭게 사용할 수 있으며, 제3자에게 동일한 범위에서 해당 저작물을 이용하도록 허락할 수 있습니다.",
      },
    ],
  },
  {
    title: "제 18 조 (서비스 이용)",
    sub2: [
      {
        comment:
          "1) 회사는 업무상, 기술상 장애나 기타 특별한 사유가 없는 한 연중무휴로 1일 24시간 서비스를 제공 합니다.",
      },
      {
        comment:
          "2) 전항에도 불구하고 회사는 일부 서비스에 대하여 이용시간을 별도로 정할 수 있으며, 이 경우 서비스 이용시간을 사전에 공지합니다",
      },
      {
        comment:
          "3) 고객센터 및 서비스 상담 등의 업무는 영업일(월~금) 기준 10:30부터 17:30까지 운영되며, 해당 운영시간 중 휴게시간에는 업무처리가 어려울 수 있습니다.",
      },
      {
        comment:
          "4) 회사는 정전이나 정보통신설비의 점검, 교체, 보수 등 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 정지할 수 있습니다. 회사는 서비스 정지의 경우 사전에 공지하여야 하나 부득이한 사유가 있는 경우 사후에 공지할 수 있습니다.",
      },
      {
        comment:
          "5) 이용자가 본 이용약관에 명시된 내용 및 그 외 서비스를 회사에게 요청하는 경우, 양 당사자의 합의를 통해 별도의 서비스를 제공할 수 있으며 그에 대한 서비스 제공 계약서를 작성할 수 있습니다. 작성된 계약서의 내용은 이용자가 회사에게 요청한 서비스를 기준으로 작성됩니다.",
      },
    ],
  },
  {
    title: "제 19 조 (서비스 이용 대금)",
    sub: "서비스 이용에 대한 이용 대금은 이용자와 회사, 제휴회원과의 협의를 통하여 결정되고, 서비스 정책의 변동에 따라 변경될 수 있으며, 중대한 변경이 있을 경우 이는 사전에 공지합니다.",
  },
  {
    title: "제 20 조 (계약해지 및 이용제한)",
    sub2: [
      { comment: "1) 이용자는 언제든지 서비스 이용을 해지할 수 있습니다." },
      {
        comment:
          "2) 회사는 이용자가 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우 회사는 일방적으로 계약을 해지할 수가 있습니다.",
      },
      {
        comment:
          "3) 회사는 본 약관 외에도 일부 서비스에 적용되는 이용 수칙을 수립하여 이용자에게 공지할 수 있으며, 회사는 이용자가 이용 수칙을 위반한 경우에도 제2항에 규정된 조치를 취할 수 있습니다.",
      },
      {
        comment:
          "4) 본 계약이 해지 된 경우에도 제휴회원이 등록한 미디어는 삭제되지 않습니다",
      },
    ],
  },
  {
    subject: "제 5장 계약해지 및 이용제한",
    class: "margin",
    title: "제 21 조 (면책조항)",
    sub2: [
      {
        comment:
          "1) 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공 장애로 인한 관한 책임이 면제됩니다.",
      },
      {
        comment:
          "2) 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.",
      },
      {
        comment:
          "3) 회사는 이용자가 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 책임을 지지 않습니다.",
      },
      {
        comment:
          "4) 회사는 서비스를 매개로 한 이용자들 간 거래 또는 이용자와 제3자 상호간 거래에 대하여 책임을 지지 않습니다.",
      },
      {
        comment:
          "5) 회사는 서비스 이용과 관련하여 이용자에게 발생한 손해 가운데 이용자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.",
      },
    ],
  },
  {
    title: "제 22 조 (준거법 및 관할 법원)",
    sub2: [
      {
        comment:
          "1) 회사와 이용자 사이에 제기된 소송은 대한민국법을 준거법으로 하며, 이 약관에 명시되지 않은 사항에 대해서는 관계법령에 의하고, 법에 명시되지 않은 부분에 대하여는 상관례에 의합니다.",
      },
      {
        comment:
          "2) 회사와 이용자 사이에 발생한 소송의 관할법원은 민사소송법에 따라 정합니다.",
      },
      {
        comment:
          "3) 전항에도 불구하고 해외에 주소나 거소가 있는 이용자 사이에 발생한 소송의 경우에는 회사 주소지의 법원을 관할 법원으로 합니다.",
      },
      { comment: `<부칙> 본 약관은 2023년 11월 22일부터 적용됩니다.` },
    ],
  },
];

export const PersonalService = [
  {
    title:
      "“주식회사 블록테크”에서 제공하는 서비스(이하 “회사”)는 이용자의 개인정보를 매우 중요하게 생각하며 정보통신서비스 제공자가 준수하여야 하는 관련 법령 및 규정을 준수하고 있습니다. 본 개인정보처리방침은 회사의 웹사이트에 적용되며, 다음과 같은 내용을 담고 있습니다.",
    sub2: [
      { comment: "1) 개인정보의 수집∙이용 목적" },
      { comment: "2) 수집하는 개인정보 항목 및 보유 기간" },
      { comment: "3) 개인정보의 제3자 제공" },
      { comment: "4) 개인정보의 취급위탁" },
      { comment: "5) 개인정보 보유 및 이용기간" },
      { comment: "6) 개인정보 파기절차 및 방법" },
      { comment: "7) 이용자 및 법정대리인의 권리" },
      { comment: "8) 쿠키의 운영 및 수집 거부 방법" },
      { comment: "9) 개인정보 보호를 위한 기술적∙관리적 보호 대책" },
      { comment: "10) 개인정보 관리책임자 및 담당부서" },
      { comment: "11) 링크 사이트에 대한 책임" },
      { comment: "12) 개인정보처리방침의 고지 의무" },
    ],
  },
  {
    title: "1. 개인정보의 수집 이용 목적",
    sub: "회사는 아래와 같은 목적으로 서비스 제공을 위한 최소한의 개인정보만을 수집하며, 수집한 정보를 목적 외로 사용하거나, 이용자의 동의 없이 외부에 공개하지 않습니다.",
    sub2: [
      {
        comment:
          " 1) 이용자 관리 : 서비스 제공에 따른 개인식별, 서비스 이용 의사 확인, 이용약관 위반 이용자에 대한 이용제한 조치, 서비스 부정 이용 제재, 비인가 사용 방지 및 서비스 제공 및 계약의 이행, 고충 처리 및 분쟁 조정을 위한 기록 보존, 고지사항 전달, 서비스 이용 해지 의사의 확인, 서비스 이용 및 상담, 문의, 후기 등 원활한 의사 소통 경로 확보, 맞춤형 서비스 제공, 거점 기반 서비스 제공 등",
      },
      {
        comment:
          " 2) 서비스 개발 및 마케팅 광고 활용 : 신규 서비스 개발 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트· 광고성 정보 및 참여 기회 제공, 접속빈도 파악, 이용자의 서비스 이용에 대한 통계",
      },
    ],
  },
  {
    title: "2. 수집하는 개인정보 항목 및 보유 기간",
    sub: "회사는 아래의 경우 개인정보를 수집합니다.",
    sub3: "가. 이메일 수집",
    tableWidth: "650px",
    tableTitle: [
      { title: "수집 목적" },
      { title: "수집 항목" },
      { title: "보유기간" },
    ],
    tableValue: [
      { value: "서비스 이용을 위한 이메일 수집" },
      { value: "회사 이름, 이메일 주소" },
      {
        value: `서비스 이용 종료 시점부터 3개월
      *부정이용기록
      (불량 혹은 비정상 이용기록)의 경우 2년`,
      },
    ],
  },
  {
    tableWidth: "820px",
    sub3: "나. 서비스 이용",
    tableTitle: [
      { title: "수집 목적" },
      { title: "수집 항목" },
      { title: "보유기간" },
    ],
    tableZone: true,
  },
  {
    title: "3. 개인정보의 제3자 제공",
    tableWidth: "820px",
    tableTitle: [
      { title: "제공 받는자" },
      { title: "제공 목적" },
      { title: "제공 항목" },
      { title: "보유 및 이용기간" },
    ],
    // tableValue: [
    //   { value: "제휴 회원" },
    //   { value: "의뢰한 서비스 제공을 위함" },
    //   { value: "요청 서비스 항목에 맞는 기업 정보 등" },
    //   {
    //     value:
    //       "재화 또는 서비스 제공을 완료한 후, 내부 방침 및 기타 관련법령에 의한 정보보호 사유에 따라 (제5조. 개인정보 보유 및 이용기간) 일정 기간 저장 후 파기",
    //   },
    // ],
    tableZone3: true,
  },
  {
    sub: "회사는 원활한 서비스 제공을 위하여 관련 정보를 필요 범위 내에서 아래 업체에게 제공합니다.",
    sub2: [
      {
        comment:
          "1) 회사는 회원의 개인정보를 동의 없이 제3자에게 제공하지 않습니다. 다만, 법령의 규정에 의한 경우는 예외로 합니다.",
      },
      {
        comment:
          "2) 이용자가 이용약관 및 사이트에 명시되지 않은 내용 또는, 그 외 서비스를 회사에게 요청하는 경우, 합의를 통하여 별도의 서비스를 제공할 수 있고, 그에 대한 서비스 계약서와 별도의 개인정보활용 동의서로 사전 동의 및 계약을 진행할 수 있습니다.",
      },
    ],
  },
  {
    title: "4. 개인정보 보유 및 이용기간",
    tableWidth: "650px",
    tableTitle: [
      { title: "보존 근거" },
      { title: "보존 기록" },
      { title: "보유 기간" },
    ],
    tableZone2: true,
  },
  {
    sub: "회사는 원칙적으로 이용자의 개인정보 수집·이용 목적이 달성되면 지체 없이 파기합니다. 단, 다음의 경우는 예외로 합니다.",
  },
  {
    title: "5. 개인정보 파기절차 및 방법",
    sub: "회사는 원칙적으로 개인정보 처리목적이 달성된 경우 지체 없이 해당 개인정보를 파기하며, 파기절차 및 파기방법은 다음과 같습니다.",
  },
  {
    sub: " 1) 파기절차",
    sub2: [
      {
        comment:
          "이용자가 서비스 이용 등을 위해 입력한 정보는 수집 목적이 달성된 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, 별도의 DB로 옮겨진 개인정보는 법률에 의한 경우가 외에는 다른 목적으로 이용되지 않습니다.",
      },
    ],
  },

  {
    sub: " 2) 파기방법",
    sub2: [
      {
        comment:
          "가. 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.",
      },
      {
        comment:
          "나. 종이에 출력된 개인 정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.",
      },
    ],
  },
  {
    title: "6. 이용자 및 법정대리인의 권리",
    sub: `이용자 또는 법정대리인은 회사에 대해 언제든지 자신 혹은 만 14세 미만 아동의 개인정보 보호 관련
  권리를 행사할 수 있습니다. 이용자 또는 법정대리인은 회사의 개인정보 처리에 동의하지 않는 경우 동의 철회 혹은 회원 탈퇴를 요청할 수 있습니다. 단, 이 경우 서비스의 일부 또는 전부의 이용이 어려울 수 있습니다.`,
    sub2: [
      {
        comment:
          "1) 개인정보 조회, 수정 또는 서비스 이용 해지를 위해서는 회사에게 전자우편 및 기타 방법으로 연락하시면 지체 없이 조치됩니다.",
      },
      {
        comment:
          "2) 이용자가 개인정보의 오류에 대한 정정을 요청하는 경우에는 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.",
      },
      {
        comment: ` 3) 회사는 이용자 또는 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.`,
      },
    ],
  },
  {
    title: "7. 쿠키의 운영 및 수집 거부 방법",
    sub: " 1) 회사는 이용자에게 맞춤형 서비스를 제공하고, 보다 신속한 서비스 제공을 위해 이용자에 대한 정보를 저장하고 수시로 불러오는 ‘쿠키(Cookie)’를 사용합니다. 쿠키란 웹사이트 방문 시, 서버가 이용자의 하드디스크에 저장하는 작은 기록 정보 파일을 말합니다. 이후 이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공합니다. 쿠키는 개인을 식별하는 정보를 자동적·능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다. 단, 쿠키 저장을 거부하는 경우에는 일부 서비스 이용이 어려울 수 있습니다.",
  },
  {
    sub: " 2) 쿠키의 설치 / 운용 및 거부 방법 안내 이용자는 사용하는 웹 브라우저의 옵션을 설정함으로써 모든 쿠키를 허용 혹은 거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 지정할 수 있습니다.",
    sub2: [
      {
        comment:
          "가. Internet Explorer 의 경우 [도구]메뉴에서 [인터넷 옵션]을 선택합니다. [개인정보 탭]에서 [개인정보 취급 수준]을 설정합니다.",
      },
      {
        comment:
          "나. Chrome의 경우 [설정] 하단 [고급설정 표시]를 클릭합니다. [개인정보] 콘텐츠 설정 → [쿠키] 영역에서 원하시는 정책을 선택합니다.",
      },
    ],
  },
  {
    title: "8. 개인정보 보호를 위한 기술적∙관리적 보호 대책",
    sub: "”회사”는 이용자들의 개인정보 보호를 위해 다음과 같은 기술적∙관리적 노력을 하고 있습니다.",
    sub2: [
      {
        comment:
          "1) 개인정보 암호화 이용자의 비밀번호 등 중요정보는 암호화되어 저장, 관리되고 있으며, 개인정보의 확인 및 변경은 본인에 의해서만 가능합니다.",
      },
      {
        comment:
          " 2) 해킹 등에 대비한 대책회사는 해킹이나 악성코드에 의하여 이용자들의 개인정보가 유출∙훼손되는 것을 방지하기 위하여 침입차단 시스템을 24시간 운영하여 외부로부터의 무단접근을 통제하고 있으며, 백신 프로그램을 설치하여 시스템이 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다. 또한 개인정보의 훼손에 대비하여 자료를 수시로 백업하며, 암호화통신 등을 통해 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 더불어 회사는 더욱 안전한 개인정보 취급을 위해 최대한의 기술적 방법을 구비하기 위해 노력하고 있습니다.",
      },
      {
        comment:
          " 3) 개인정보 취급 직원의 최소화 및 교육회사는 개인정보를 취급하는 직원을 최소한으로 제한하고 있으며, 관련 직원들에 대한 수시 교육을 실시하여 개인정보처리방침의 중요성을 인지시키고 있습니다.",
      },
      {
        comment:
          "4) 개인정보보호전담조직의 운영개인정보 보호를 위해 개인정보보호전담부서를 운영하고 있으며, 개인 정보처리방침의 이행사항 및 개인정보 취급자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.",
      },
    ],
  },
  {
    title: "9. 기타 개인정보침해에 대한 신고 상담 기관",
    sub: "기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.",
    subLink: true,
  },
  {
    title: "10. 링크 사이트에 대한 책임",
    sub: "회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 그러나 링크 웹사이트들은 회사의 개인정보처리방침이 적용되지 않으므로, 해당 링크를 통해 외부 웹사이트로 이동하시는 경우, 해당 서비스의 정책을 검토하시기 바랍니다.",
  },
  {
    title: "11. 개인정보처리방침의 고지 의무",
    sub: "회사는 개인정보처리방침에 대한 변경이 있을 경우에는 개정 개인정보처리방침의 시행일로부터 최소 7일 전에 홈페이지의 공지사항 또는 이메일을 통해 고지합니다.",
  },
  { title: "12. 개정이력", sub: "개인정보처리방침 시행일: 2023년 11월 22일" },
];

export const useSideState = (ref: any, state: any, handleState: () => void) => {
  const onOutsideClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      handleState();
    }
  };

  useEffect(() => {
    if (state) {
      document.addEventListener("mousedown", onOutsideClick);
    } else {
      document.removeEventListener("mousedown", onOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", onOutsideClick);
    };
  }, [state]);
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setWindowSize({
          x: window.innerWidth,
          y: window.innerHeight,
        });
      };

      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    } else {
      return;
    }
  }, []);
  return windowSize;
};
