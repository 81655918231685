import { styled } from "styled-components";
import Company from "../component/company";
import Develop from "../component/develop";
import Solution from "../component/solution";
import Block from "../component/block";
import { useOutletContext } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Business from "../component/business";
import { useSideState, useWindowSize } from "../static/menu";
import AOS from "aos";

const Index: React.FC<any> = () => {
  const [click, setClick] = useState<boolean>(false);

  const handleClick = () => {
    setClick(!click);
  };
  const ref = useRef(null);
  useSideState(ref, click, handleClick);

  useEffect(() => {
    AOS.init();
  });

  const HeaderMenu = [
    {
      label: "회사소개",
      value: "company",
      com: <Company setClick={handleClick} />,
    },
    {
      label: "디파이, 크립토 홈페이지 개발의뢰",
      value: "develop",
      com: <Develop />,
    },
    {
      label: "마케팅/솔루션 의뢰",
      value: "solution",
      com: <Solution />,
    },
    {
      label: "가상자산 사업 자문의뢰",
      value: "block",
      com: <Block setClick={handleClick} />,
    },
  ];

  return (
    <Container ref={ref}>
      {HeaderMenu.map((item, i) => (
        <div
          id={item.value}
          key={i}
          // data-aos="fade-down"
          // data-aos-duration="5000"
          // data-aos-delay="1000"
        >
          {item.com}
        </div>
      ))}
      {click && (
        <div className="modal">
          <Business setClick={handleClick} />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .modal {
    background: #0a0a0a99;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;
export default Index;
