const SubTable = () => {
  const name =
    "이름, 생년월일, 전화번호, 사업자등록번호 등 서비스 의뢰시 필요 항목";
  const service = (
    <p className="small">
      서비스 제공기간 (관계법령의 규정에 의하여 보존할 필요가 있는 경우 및
      <br /> 사전 동의를 득한 경우 해당 보유 기간)
    </p>
  );

  return (
    <>
      <tr>
        <td>
          <p className="small">개발 의뢰</p>
        </td>
        <td>
          <p className="small">{name}</p>
        </td>
        <td>{service}</td>
      </tr>
      <tr>
        <td>
          <p className="small">
            마케팅∙솔루션
            <br /> 의뢰
          </p>
        </td>
        <td>
          <p className="small">{name}</p>
        </td>
        <td>{service}</td>
      </tr>

      <tr>
        <td>
          <p className="small">
            가상자산 사업
            <br /> 자문의뢰
          </p>
        </td>
        <td>
          <p className="small">{name}</p>
        </td>
        <td>{service}</td>
      </tr>
      <tr>
        <td>
          <p className="small">
            기타 원활한
            <br /> 서비스 제공
          </p>
        </td>
        <td>
          <p className="small">
            [공통] 쿠키, 접속IP, 접속환경, 접속일시, 불량 혹은 비정상 이용기록,
            <br />
            서비스이용기록
          </p>
        </td>
        <td>
          <p className="small">
            서비스 이용 종료 시점부터 3개월
            <br /> *부정이용기록(불량 혹은 비정상 이용기록)의 경우 2년
          </p>
        </td>
      </tr>
    </>
  );
};

export default SubTable;
