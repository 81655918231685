import { styled } from "styled-components";
import { UseService } from "../static/menu";
import { media } from "../styles/media";

const UseForm = () => {
  return (
    <Container>
      <h1 className="h1Title">블록테크 이용약관</h1>
      {UseService.map((item, i) => (
        <div className="width-box">
          <h3
            className={item.class === "margin" ? "subject margin" : "subject"}
          >
            {item.subject}
          </h3>
          <h2 className="h2Title">{item.title}</h2>
          <span className="spanSub">{item.sub}</span>
          {item.sub2?.map((item, i) => (
            <div className="subMap">
              <span className="spanSub">{item.comment}</span>
            </div>
          ))}
          {item.sub3?.map((item, i) => (
            <div className="subMapSec">
              <span className="spanSub">{item.comment}</span>
            </div>
          ))}
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 40px;
  .width-box {
    flex-direction: column;
  }
`;

export default UseForm;
