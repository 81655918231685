import { css, styled } from "styled-components";
import React, { useRef, useState } from "react";
import { useSideState } from "../static/menu";
import { media } from "../styles/media";
import { Link } from "react-router-dom";

const Header: React.FC<any> = ({ height }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setMenuOpen(!menuOpen);
  };
  const ref = useRef(null);
  useSideState(ref, menuOpen, handleOpen);

  const HeaderMenu = [
    { label: "회사소개", value: "company", top: 0, mTop: 0 },
    {
      label: "디파이, 크립토 홈페이지 개발의뢰",
      value: "develop",
      top: 534,
      mTop: height.y - 66,
    },
    {
      label: "마케팅/솔루션 의뢰",
      value: "solution",
      top: 1068,
      mTop: height.y + 781 - 66,
    },
    {
      label: "가상자산 사업 자문의뢰",
      value: "block",
      top: 1568,
      mTop: height.y * 3,
    },
  ];

  return (
    <Container open={menuOpen} ref={ref}>
      <div className="width-box">
        <div className="media-header">
          <div className="header">
            <Link to="/">
              <img
                src="/images/logo.svg"
                width={180}
                height={32}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              />
            </Link>
          </div>
          <button className="mediaH" onClick={handleOpen}>
            <div className="three col">
              <div
                className={menuOpen ? "hamburger active" : "hamburger"}
                id="hamburger"
              >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </div>
          </button>
        </div>
        {menuOpen && (
          <div className="header media">
            {HeaderMenu.map((item, i) => (
              // <a href={`#${item.value}`} key={i}>
              <button
                onClick={() =>
                  window.scrollTo({ top: item.mTop, behavior: "smooth" })
                }
              >
                <h4>{item.label}</h4>
              </button>
              // </a>
            ))}
          </div>
        )}

        <div className="header pc">
          {HeaderMenu.map((item, i) => (
            // <a href={`#${item.value}`} key={i}>
            <button
              onClick={() =>
                window.scrollTo({ top: item.top, behavior: "smooth" })
              }
            >
              <h4>{item.label}</h4>
            </button>
            // </a>
          ))}
        </div>
      </div>
    </Container>
  );
};

interface style {
  open: boolean;
}

const Container = styled.header<style>`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px 0;
  box-shadow: 0px 6px 6px 0px #0a0a0a0a;
  position: sticky;
  top: -1px;
  background-color: #fff;
  z-index: 99;
  /* position: relative; */

  .width-box {
    justify-content: space-between;
    align-items: center;
  }
  button {
    margin: 0 10px;
  }
  .mediaH {
    display: none;
  }

  .media {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    box-shadow: 0px 6px 6px 0px #0a0a0a0a;
    background-color: #fff;
    animation: fadeDown 1s;
    height: ${(props) => (props.open ? "170px" : "0")};
    button {
      text-decoration: none;
      display: flex;
      height: 100%;
      justify-content: space-between;
    }
  }

  .three {
    width: 20%;
  }

  .col {
    display: block;
    float: left;
    margin: 1% 0 1% 1.6%;
  }

  .col:first-of-type {
    margin-left: 0;
  }

  .three {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    text-align: center;
  }

  .hamburger .line {
    width: 20px;
    height: 2px;
    background-color: #111;
    display: block;
    margin: 4px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  /* ONE */

  #hamburger.active .line:nth-child(2) {
    opacity: 0;
  }

  #hamburger.active .line:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
    -ms-transform: translateY(6px) rotate(45deg);
    -o-transform: translateY(6px) rotate(45deg);
    transform: translateY(6px) rotate(48deg);
  }

  #hamburger.active .line:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
    -ms-transform: translateY(-6px) rotate(-45deg);
    -o-transform: translateY(-6px) rotate(-45deg);
    transform: translateY(-6px) rotate(-45deg);
  }
  ${media.tablet`
    .pc {
      display: none;
    }
    .media-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .mediaH {
      display: flex;
      flex-direction: column;
      
    }
  `}

  @keyframes fadeDown {
    0% {
      opacity: 0;
      height: 0;
    }
    to {
      opacity: 1;
      height: 170px;
    }
  }
`;

export default Header;
