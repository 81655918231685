const SubTableThi = () => {
  return (
    <tr>
      <td>
        <p className="small">제휴 회원</p>
      </td>
      <td>
        <p className="small">
          의뢰한 서비스
          <br /> 제공을 위함
        </p>
      </td>
      <td>
        <p className="small">
          요청 서비스 항목에 맞는
          <br /> 기업 정보 등
        </p>
      </td>
      <td>
        <p className="small">
          재화 또는 서비스 제공을 완료한 후, 내부 방침 및 기타 관련법령에 의한
          정보보호
          <br /> 사유에 따라 (제5조. 개인정보 보유 및 이용기간) 일정 기간 저장
          후 파기
        </p>
      </td>
    </tr>
  );
};

export default SubTableThi;
