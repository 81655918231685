import { styled } from "styled-components";
import { DevelopImg } from "../static/menu";
import { media } from "../styles/media";

const Develop: React.FC<any> = () => {
  return (
    <Container>
      <div className="width-box">
        <div className="part">
          <span className="first">Web page developing</span>
          <h1>
            프로젝트 런칭을 위한 완벽한 패키지
            <br />
            홈페이지, 디자인, 블록체인
          </h1>
          <span className="sec">
            한 번에 홈페이지 기획, 디자인, 그리고 블록체인 기술을 적용하여
            <br /> 프로젝트 런칭을 완벽하게 지원합니다.
          </span>
        </div>
        <div className="part img-box">
          {DevelopImg.map((item, i) => (
            <div key={i} className="position">
              <div
                className="back-img"
                style={{
                  backgroundImage: `url(${item.img})`,
                  width: "154px",
                  height: "245px",
                  backgroundSize: "cover",
                  borderRadius: "20px",
                }}
              >
                <div className="back"></div>
              </div>
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 534px;
  .width-box {
    justify-content: space-between;
    align-items: center;
  }
  .back {
    background: linear-gradient(
      180deg,
      rgba(34, 34, 34, 0) 0%,
      rgba(34, 34, 34, 0.6) 100%
    );

    height: 100%;
    border-radius: 20px;
  }
  .img-box {
    display: flex;
    .position {
      position: relative;
      margin-right: 10px;
    }

    span {
      z-index: 5;
      padding: 20px;
      position: absolute;
      top: 170px;
      left: 0;
      z-index: 1;
      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      color: #fff;
      text-align: left;
    }
  }
  h1,
  span {
    color: #222222;
  }
  .sec {
    color: #9d9d9d;
  }
  ${media.tablet`
    height: auto;
    .img-box {
        flex-direction: column;
        span {
          bottom: 0!important;
          top: initial;
        }
    }
    .back-img {
            width: 100% !important;
            height: 140px !important;
        }
    
    .width-box {
        align-items: center;
    }
    .position {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
    .part {
        margin-top: 30px;
        width: 100%;
    }
  `}
`;
export default Develop;
