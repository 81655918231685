import { createGlobalStyle, css } from "styled-components";
import { media } from "./media";

const globalStyle = css`
  @import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

  @font-face {
    /* font-family: "bbb-invader";
    font-display: fallback;

    src: url("/fonts/BBBInvader-Regular.otf") format("opentype"); */
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    transition: all 1s;
    font-family: "Pretendard", sans-serif !important;
  }

  .width-box {
    width: 80%;
    display: flex;
  }
  button {
    cursor: pointer;
    white-space: nowrap;
  }
  button,
  input {
    background: none;
    border: none;
  }
  img {
    object-fit: cover;
  }
  button,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  textarea {
    font-family: "Pretendard", sans-serif !important;
  }

  h4 {
    color: #9d9d9d;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  h5 {
    color: #9d9d9db2;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
  }
  .first,
  .sec,
  h1 {
    animation: fadeIn 1s;
  }
  .first {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    white-space: nowrap;
  }
  .sec {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }

  h1 {
    font-size: 46px;
    font-weight: 700;
    line-height: 55px;
    margin: 15px 0;
  }

  .button {
    width: 155px;
    height: 47px;
    padding: 14px 20px;
    border-radius: 600px;
    border: 1px solid #ffffff;
    gap: 10px;
    margin-top: 40px;
    span {
      font-weight: 700;
    }
    &:hover {
      background-color: #fff;
      span {
        color: #111;
      }
    }
  }

  .h1Title {
    font-size: 48px;
    line-height: 57px;
    text-align: center;
  }
  .h2Title {
    font-size: 24px;
    line-height: 29px;
    text-align: left;
  }
  .subject {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
  .spanSub {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #535353;
  }
  .h1Title,
  .h2Title,
  .subject {
    font-family: Pretendard;
    font-weight: 700;
    letter-spacing: 0em;
    color: #222222;
  }
  .md {
    display: none;
  }

  ${media.tablet`
    .pc {
      display: none;
    }
    .md {
      display: flex;
    }
    h1 {
      font-size: 20px;
      line-height: 24px;
      margin: 8px 0;
    }
    .first {
      font-size: 10px;
      line-height: 12px;
    }
    .sec {
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
    }
    h5 {
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
    }
    .width-box {
      width: 90%;
      flex-direction: column;
    }
  `}

  .subMap {
    margin: 10px 0;
  }
  .subMapSec {
    margin: 8px 20px;
  }
  .h2Title {
    margin: 5px 0;
  }
  .spanSub {
    margin: 8px 0;
  }
  .margin {
    margin: 50px 0;
  }
  ${media.tablet`
        .h1Title {
            font-size: 32px;
            line-height: 38px;
        }
        .h2Title {
            font-size: 18px;
        }
        .subject {
            font-size: 18px;

        }
        .spanSub {
            font-size: 14px;
        }
        .subMap {
            margin: 5px 0;
        }
        .subMapSec {
            margin: 5px 20px;
        }
        .margin {
            margin: 30px 0;
        }
  `}
`;

const GlobalStyle = createGlobalStyle`
  ${globalStyle}

`;
export default GlobalStyle;
