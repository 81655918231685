import { Link } from "react-router-dom";

const SubLink = () => {
  return (
    <>
      <div style={{ marginBottom: "5px" }}>
        <span className="spanSub">
          1) 개인정보침해신고센터 (
          <button
            className="link"
            onClick={() => window.open("https://privacy.kisa.or.kr/")}
          >
            <span className="spanSub">privacy.kisa.or.kr</span>
          </button>{" "}
          / 국번없이 118 )
        </span>{" "}
        <span className="spanSub">
          개인정보분쟁조정위원회 (
          <button
            className="link"
            onClick={() => window.open("https://www.kopico.go.kr/main/main.do")}
          >
            <span className="spanSub">www.kopico.go.kr</span>
          </button>{" "}
          / 1833-6972 )
        </span>
      </div>
      <span className="spanSub">
        2) 대검찰청 사이버 수사과 ({" "}
        <button
          className="link"
          onClick={() => window.open("https://www.spo.go.kr/site/spo/main.do")}
        >
          <span className="spanSub">www.spo.go.kr</span>
        </button>{" "}
        / 국번없이 1301)
      </span>
      <span className="spanSub">
        3) 경찰청 사이버안전지킴이 ({" "}
        <button
          className="link"
          onClick={() =>
            window.open("https://www.police.go.kr/www/security/cyber.jsp")
          }
        >
          <span className="spanSub">
            www.police.go.kr/www/security/cyber.jsp
          </span>
        </button>{" "}
        / 국번없이 182 )
      </span>
    </>
  );
};
export default SubLink;
