import { styled } from "styled-components";
import { media } from "../styles/media";
import { useWindowSize } from "../static/menu";

const Block: React.FC<any> = ({ setClick }) => {
  const back = "/images/bottom-pc.png";
  const backM = "/images/bottom-m.png";
  const width = useWindowSize();
  const w = width.x >= 768;

  return (
    <Container back={back} $backM={backM} w={w}>
      <div className="back-box">{/* <div className="back"></div> */}</div>
      <div className="comment" id="bottom">
        <h1 className="pc">
          블록체인 분야에서 시도하고 싶은 사업이 있다면
          <br /> 그 어떤 아이디어도 환영합니다.
        </h1>
        <h1 className="md">
          블록체인 분야에서 시도하고 싶은 사업이
          <br /> 있다면 그 어떤 아이디어도 환영합니다.
        </h1>
        <span className="first pc">
          블록체인을 활용하여 아이디어를 현실로 만들어 비즈니스 경쟁에서 우위를
          차지하세요.
        </span>
        <span className="sec md">
          블록체인을 활용하여 아이디어를 현실로 만들어 비즈니스 경쟁에서
          <br /> 우위를 차지하세요.
        </span>
        <button className="button" onClick={() => setClick()}>
          <span className="first">비즈니스 문의하기</span>
        </button>
      </div>
    </Container>
  );
};

interface style {
  back: string;
  $backM: string;
  w?: boolean;
}
const Container = styled.div<style>`
  height: 534px;
  position: relative;
  justify-content: center;
  display: flex;
  .first,
  h1 {
    color: #fff;
    text-align: center;
  }
  .back {
    background: #030023b2;
    background-blend-mode: overlay;
    height: 100%;
  }
  .back-box {
    background-image: ${(props) => props.back && `url(${props.back})`};
    height: 100%;
    width: 100%;
    /* z-index: -1; */
    background-size: cover;
    /* background-attachment: fixed; */

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
  .comment {
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  ${media.tablet`
  height: 800px;
    .back-box {
      background-image: ${(props: any) =>
        props.$backM && `url(${props.$backM})`};
    }

    .comment {
      h1 {
        padding: 0 10px;
      }
    }
    .sec {
      color: #Fff;
      text-align: center;
      line-height: 16px;
    }
  `}
`;
export default Block;
