import { styled } from "styled-components";
import { media } from "../styles/media";

const Solution = () => {
  return (
    <Container>
      <div className="width-box">
        <img src="/images/img5.jpg" width={650} height={245} />
        <div className="part">
          <span className="first">Marketing / Solution</span>
          <h1>
            크립토 시장 최적화 마케팅으로
            <br />
            프로젝트 성장을 이루세요!
          </h1>
          <span className="sec">
            성공적인 프로젝트는 모두 훌륭한 마케팅이 기반되었습니다.
            <br /> 크립토 시장에 최적화된 마케팅을 통해 프로젝트를 성장시키고
            확장하세요.
          </span>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  margin-bottom: 100px;
  img {
    border-radius: 20px;
  }
  .width-box {
    justify-content: space-between;
    align-items: center;
  }
  .sec {
    color: #9d9d9d;
  }
  ${media.tablet`
    height: 534px;
    margin-bottom: 50px;
    img {
      width: 100%;
      height: 320px;
    }
    .width-box {
      flex-direction: column-reverse;
    }
    .part {
      margin-bottom: 20px;
    }
  `}
`;
export default Solution;
