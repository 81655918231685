import { styled } from "styled-components";
import { PersonalService } from "../static/menu";
import SubTable from "./subTable";
import SubTableSec from "./subTableSec";
import { media } from "../styles/media";
import SubTableThi from "./subTableThi";
import SubLink from "./subLink";

const Info = () => {
  return (
    <Container>
      <h1 className="h1Title">블록테크 개인정보 처리방침</h1>
      {PersonalService.map((item, i) => (
        <div className="width-box">
          <h2 className="h2Title">{item.title}</h2>
          <span className="spanSub">{item.sub}</span>
          <span className="spanSub">{item.sub3}</span>
          {item.sub2?.map((item, i) => (
            <span className="spanSub">{item.comment}</span>
          ))}
          {item.subLink && <SubLink />}
          <div className="tableBox">
            <table border={1} width={item.tableWidth}>
              {item.tableTitle?.map((item, i) => (
                <th>
                  <p className="tabelTitle">{item.title}</p>
                </th>
              ))}
              <tr>
                {item.tableValue?.map((item, i) => (
                  <td>
                    <p className="small">{item.value}</p>
                  </td>
                ))}
              </tr>
              {item.tableZone && <SubTable />}
              {item.tableZone3 && <SubTableThi />}
              {item.tableZone2 && <SubTableSec />}
            </table>
          </div>
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 40px;
  width: 100%;
  .width-box {
    flex-direction: column;
  }
  .small {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    white-space-collapse: break-spaces;
  }
  .tabelTitle {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
  }
  .link {
    span {
      color: #3246ea;
      text-decoration: underline;
    }
  }

  table,
  td,
  th {
    border: 1px solid #222;
    border-collapse: collapse;
    text-align: center;
  }
  th {
    padding: 10px;
  }
  td {
    padding: 25px 0;
  }
  .h2Title {
    margin-top: 8px;
  }
  .width-box:nth-child(12),
  .width-box:nth-child(15) {
    .spanSub:not(:first-of-type) {
      margin-left: 20px !important;
    }
  }
  .tableBox {
    overflow-y: scroll;
  }

  .spanSub {
    margin: 6px 0 !important;
  }
  overflow: hidden;
  ${media.tablet`
 
    .spanSub {
        margin: 2px 0!important;
    }
  `}
`;

export default Info;
