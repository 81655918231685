const SubTableSec = () => {
  return (
    <>
      <tr>
        <td>
          <p className="small">통신비밀보호법</p>
        </td>
        <td>
          <p className="small">서비스 이용 관련 개인정보</p>
        </td>
        <td>
          <p className="small">3개월</p>
        </td>
      </tr>
      <tr>
        <td>
          <p className="small">
            전자상거래 등에서의 소비자보호에
            <br /> 관한 법률
          </p>
        </td>
        <td>
          <p className="small">서비스 이용 관련 개인정보</p>
        </td>
        <td>
          <p className="small">5년</p>
        </td>
      </tr>

      <tr>
        <td>
          <p className="small">
            전자상거래 등에서의 소비자보호에
            <br /> 관한 법률
          </p>
        </td>
        <td>
          <p className="small">대금결제 및 재화 등의 공급에 관한 기록</p>
        </td>
        <td>
          <p className="small">5년</p>
        </td>
      </tr>
      <tr>
        <td>
          <p className="small">
            전자상거래 등에서의 소비자보호에
            <br /> 관한 법률
          </p>
        </td>
        <td>
          <p className="small">소비자의 불만 또는 분쟁처리에 관한 기록</p>
        </td>
        <td>
          <p className="small">5년</p>
        </td>
      </tr>
      <tr>
        <td>
          <p className="small">회사 내부 방침</p>
        </td>
        <td>
          <p className="small">
            부정이용기록(불량 혹은 비정상 이용기록): 휴대전화번호, 이메일주소,
            <br />
            IP, 로그기록
          </p>
        </td>
        <td>
          <p className="small">1년</p>
        </td>
      </tr>
    </>
  );
};

export default SubTableSec;
