import { ChangeEvent, useState } from "react";
import Select from "react-select";
import { styled } from "styled-components";
import { media } from "../styles/media";
import { Api, Dev } from "../static/menu";
import { Link } from "react-router-dom";

interface Option {
  readonly value: string;
  readonly label: string;
  readonly isDisabled?: boolean;
}

interface GroupedOption {
  readonly label: string;
  readonly options: readonly Option[];
}

interface FormData {
  company_name: string;
  company_email: string;
  company_type: any;
  company_contents: string;
}

const options: Option[] = [
  { value: "business", label: "사업 제휴" },
  { value: "service", label: "서비스 / 제품 문의" },
  { value: "etc", label: "기타" },
];

const groupedOptions: GroupedOption[] = [
  {
    label: "문의 유형",
    options: options,
  },
];

const Business: React.FC<any> = ({ setClick }) => {
  const [submit, setSubmit] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    company_name: "",
    company_email: "",
    company_type: options[0]?.label,
    company_contents: "",
  });
  const ok =
    formData.company_contents &&
    formData.company_email &&
    formData.company_name &&
    check;
  const api = process.env.REACT_APP_NODE_ENV === "development" ? Dev : Api;

  const handleSubmit = async () => {
    if (!formData?.company_name) {
      alert("회사, 또는 프로젝트 이름을 입력해주세요.");
    } else if (!formData.company_email) {
      alert("이메일을 확인해주세요");
    } else if (!formData.company_contents) {
      alert("문의 내용을 입력해주세요.");
    } else if (!check) {
      alert("이용약관 및 개인정보처리 방침에 동의해주세요.");
    } else if (check) {
      try {
        const response = await fetch(`${api}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        if (!response.ok) {
          console.error("Failed");
          return;
        }
        console.log("success");
        setSubmit(true);
      } catch (error) {
        console.error("Error", error);
      }
    }
  };

  const get = () => {
    fetch(`${api}name?company_name=${formData.company_name}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => console.log(res));
  };

  const checkEmail = (e: ChangeEvent<HTMLInputElement>) => {
    var regExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    const test = regExp.test(e.target.value);
    if (!test) alert("이메일을 확인해주세요");
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = () => {
    setSubmit(false);
    setClick();
  };

  return (
    <Container $formData={formData} check={ok}>
      <div className="top">
        <span className="title">비즈니스 문의</span>
        <h5>
          우리의 블록체인 솔루션은 신뢰와 효율성을 결합하여 비즈니스를 혁신하고
          <br /> 최적화하는 데 도움을 줄 것입니다.
        </h5>
      </div>
      <div className="box">
        <span className="first">회사 이름</span>
        <input
          type="text"
          className="input"
          placeholder="회사, 또는 프로젝트 이름을 입력해주세요."
          name="company_name"
          onChange={handleInputChange}
        />
      </div>
      <div className="box">
        <span className="first">E-mail</span>
        <input
          type="text"
          className="input"
          placeholder="답장을 회신받을 E-mail를 입력해주세요."
          name="company_email"
          onChange={handleInputChange}
          onBlur={checkEmail}
        />
      </div>
      <div className="box">
        <span className="first">문의 유형</span>
        <Select
          options={groupedOptions}
          defaultValue={options[0]}
          name="company_type"
          onChange={(e) => setFormData({ ...formData, company_type: e?.label })}
        />
      </div>
      <div className="box">
        <span className="first">문의 내용</span>
        <textarea
          id=""
          placeholder="문의 내용을 입력해주세요."
          name="company_contents"
          onChange={handleInputChange}
        />
      </div>
      <div className="check">
        <input
          type="checkbox"
          checked={check}
          onClick={() => setCheck(!check)}
        />
        <div>
          <h5
            style={{ color: "red", whiteSpace: "nowrap", paddingRight: "5px" }}
          >
            (필수)
          </h5>

          <h5>
            홈페이지에 게시된 본{" "}
            <Link to="/service" target="_blank">
              이용약관
            </Link>{" "}
            및{" "}
            <Link to="/privacy" target="_blank">
              개인정보처리 방침
            </Link>
            에 동의합니다.
          </h5>
        </div>
      </div>
      <div className="btn">
        <button onClick={() => setClick()} className="first">
          취소
        </button>
        <button onClick={handleSubmit} className="first">
          제출
        </button>
      </div>

      {submit && (
        <div className="sub">
          <div className="sub-box">
            <img src="/images/check.png" width={48} height={48} />
            <h5>
              문의 내용이 성공적으로 접수되었습니다.
              <br />
              빠른 시일 내에 답변을 제공해 드리겠습니다. 감사합니다.
            </h5>
          </div>
          <button onClick={onSubmit}>
            <span className="first">확인</span>
          </button>
        </div>
      )}
    </Container>
  );
};

interface style {
  $formData?: any;
  check?: any;
}
const Container = styled.div<style>`
  width: 400px;
  height: 660px;
  background-color: #fff;
  border-radius: 14px;
  padding: 20px;
  animation: fadeIn 1s;
  &,
  .sub {
    position: fixed;
    left: 20px;
    bottom: 20px;
  }

  .top {
    h5 {
      color: #111 !important;
      margin: 8px 0;
    }
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
  }
  .box {
    display: flex;
    flex-direction: column;
    .first {
      margin: 10px 0;
    }
    .input,
    textarea {
      background: #f2f4f6;
      border-radius: 8px;
      padding: 10px;
      font-size: 14px;
    }
    .input {
      height: 52px;
      width: 100%;
    }
    textarea {
      border: none;
      height: 170px;
    }
  }

  .btn {
    display: flex;
    justify-content: right;
    margin-top: 20px;
    button {
      width: 92px;
      height: 47px;
      padding: 14px 32px;
      border-radius: 8px;
      gap: 10px;
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        background: ${(props) =>
          props.check
            ? "linear-gradient(104.63deg, #3246ea 0%, #57dfea 100%)"
            : "#9097A0"};
        color: #fff;
      }
    }
  }
  .css-13cymwt-control {
    background: #f2f4f6;
    border: none;
  }
  .sub {
    width: 399px;
    /* height: 203px; */
    height: 660px;
    border-radius: 14px;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    h5 {
      color: #111;
      text-align: center;
      margin-top: 20px;
    }
    button {
      width: 100%;
      height: 47px;
      border-radius: 8px;
      background: linear-gradient(104.63deg, #3246ea 0%, #57dfea 100%);
      span {
        color: #fff;
      }
    }
  }
  .sub-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .check {
    display: flex;
    margin: 8px 3px;
    justify-content: center;
    align-items: flex-start;

    input {
      margin-right: 10px;
    }
    div {
      display: flex;
    }
  }

  ${media.tablet`
    width: 97%;
    height: 570px;
    animation: fadeInM 1s;
    &,
    .sub {
        left: 50%;
        transform: translate(-50%);
        bottom: 15px;

        height: 570px;
    }
    .box {
        textarea {
            border: none;
            height: 120px;
            }
        }
    .first {
      margin: 8px 0;
    }
    .sub {
        width: 100%;
        bottom: 0;
    }
    .btn {
      margin-top: 10px;
    }

  `}

  @keyframes fadeInM {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0) translate(-50%);
    }
    to {
      opacity: 1;
      transform: translateZ(0) translate(-50%);
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
`;
export default Business;
