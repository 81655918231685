import styled from "styled-components";
import { media } from "../styles/media";

const Company: React.FC<any> = ({ setClick }) => {
  return (
    <Container>
      <div className="position">
        <video className="videoTag" autoPlay loop muted playsInline>
          <source src="/images/video.mp4" type="video/mp4" />
        </video>
        <div className="width-box comment">
          <span className="first">What is the ‘BLOCK TECH’?</span>
          <h1>
            블록체인 프로젝트의 종합 파트너로서
            <br /> 성공을 돕습니다
          </h1>
          <span className="sec pc">
            블록테크는 블록체인 분야의 전문 인큐베이팅 회사로, 프로젝트의
            기획부터 디자인, 개발, 마케팅,
            <br /> 그리고 솔루션 제공까지 프로젝트를 성공적으로 관리하는 전체
            서비스를 제공합니다
          </span>
          <span className="sec md">
            블록테크는 블록체인 분야의 전문 인큐베이팅 회사로, 프로젝트의
            기획부터 디자인, 개발, 마케팅, 그리고 솔루션 제공까지 프로젝트를
            성공적으로 관리하는 전체 서비스를 제공합니다.
          </span>
          <button className="button" onClick={() => setClick()}>
            <span>비즈니스 문의하기</span>
          </button>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .videoTag {
    height: 534px;
    width: 100%;
    object-fit: cover;
    filter: brightness(60%);
  }
  .position {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .comment {
    position: absolute;
    top: 0;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  span,
  h1 {
    color: #fff;
    text-align: left;
  }

  button {
    width: 155px;
    height: 47px;
    padding: 14px 20px;
    border-radius: 600px;
    border: 1px solid #ffffff;
    gap: 10px;
    margin-top: 40px;
  }

  ${media.tablet`

    .videoTag {
      height: calc(100vh - 66px);
    }
    .comment {
      top: 25%;
    }
  `}
`;

export default Company;
